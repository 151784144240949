<template>
  <div class="el-form-item small el-form-item--mini">
    <label class="el-form-item__label" style="width:80px;">{{label}}：</label>
    <div class="el-form-item__content" style="margin-left:80px;">
      <div class="upload-container">
        <div class="el-upload el-upload--text">
          <div class="el-upload-dragger">
            <i class="el-icon-upload"></i>
            <div
              v-if="item.imgUrl"
              class="image-preview"
              :style="{ backgroundImage: 'url(' + item.imgUrl + ')' }"
            >
              <i class="fa fa-window-close" @click.stop="delImg(item)"></i>
            </div>
            <div v-else class="el-upload__text">
              上传图片
              <em></em>
            </div>
          </div>
          <input @change="upload" type="file" name="photoImg" class="J-imgUploadFile upload-input" />
        </div>
        <!-- <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>-->
        <!-- <a href="javascript:;" @click.prevent="dialogShow=true">图片地址</a> -->
        <div v-if="item.limit" class="upload-tips">
          <h3>图片限制</h3>格式：jpeg/png/gif
          <br />
          大小：小于{{item.limit.s}}k
          <br />宽度：
          <font color="red">{{item.limit.w}}px</font>
          <br />高度：
          <font color="red">{{item.limit.h}}px</font>
        </div>
        <div v-else class="upload-tips">
          <h3>图片限制</h3>格式：jpeg/png/gif
          <br />大小：jpeg/png小于100k，gif小于500k
          <br />宽度：小于等于750px
          <a-alert v-if="tip" :message="tip" type="info" />
        </div>

      </div>
      <div class="mt-10">
        <a-alert v-if="!item.imgUrl" type="error" message="请上传图片"></a-alert>
      </div>
    </div>

    <el-dialog
      id="netImgDialog"
      title="网络图片"
      :close-on-click-modal="false"
      :append-to-body="true"
      :visible="dialogShow"
      @close="dialogShow=false"
      width="680px"
    >
      <el-tabs v-model="currentTab" type="card" @tab-click="clickTab">
        <el-tab-pane label="网络图片" name="outside">
          <el-form label-width="100px" style="margin-top:20px;">
            <el-form-item label="图片地址：">
              <el-input placeholder="图片地址，例：https://www.example.com/text.png" v-model="imgUrl"></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <!-- <el-tab-pane label="本站图片素材" name="inside">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="name" label="预览" width="80">
              <template>
                <img
                  style="max-width:50px;max-height:50px;"
                  src="http://www.w3school.com.cn/i/eg_tulip.jpg"
                />
              </template>
            </el-table-column>
            <el-table-column prop="date" label="上传日期" width="100"></el-table-column>
            <el-table-column prop="address" label="图片地址"></el-table-column>
            <el-table-column fixed="right" label="操作" width="60">
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.row)" type="text" size="small">选择</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>-->
      </el-tabs>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="setImgUrl">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { baseURL } from "@/config/index";
import { generateUUID } from "@/utils/index";
import { getOssToken } from "@/service/user";
export default {
  name: "upload",
  data() {
    return {
      currentTab: "outside",
      imgUrl: "",
      dialogShow: false,
      tableData: [],
      uploadAction: `${baseURL}/api/v1/upload-file/`,
      oss: null,
      nextImgWidth:
        this.allItems && this.allItems.length && this.allItems[0]["width"],
      nextImgHeight:
        this.allItems && this.allItems.length && this.allItems[0]["height"]
    };
  },
  props: {
    label: {
      type: String
    },
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object
    },
    allItems: {
      type: Array
    },
    isSwiper: {
      type: Boolean,
      default: false
    },
    tip: {
      type: String,
      default: '单排四个建议尺寸：180px*112px，单排五个建议尺寸：144px*88px'
    }
  },
  watch: {
    allItems: {
      handler(newValue) {
        // 图片个数
        const imgsCount = newValue.reduce(function(prev, current) {
          if (current.imgUrl.length) {
            return prev + 1;
          } else {
            return prev;
          }
        }, 0);
        // 有图片
        if (imgsCount) {
          // 获取第一个图片的尺寸
          const idx = newValue.findIndex(i => i.imgUrl);
          this.nextImgWidth = newValue[idx]["width"];
          this.nextImgHeight = newValue[idx]["height"];
        } else {
          // 图片宽高设置为null
          this.nextImgWidth = null;
          this.nextImgHeight = null;
        }
      },
      deep: true
    }
  },
  methods: {
    // 获取osstoken
    async getOssToken() {
      const { err, res } = await getOssToken();
      if (!err && res.success) this.oss = res.data;
    },
    // 文件上传方法
    async handleFileUpload(file, imgInfo, e) {
      if (!this.oss) await this.getOssToken();
      if (!this.oss) {
        this.$message.warn("上传失败");
        return false;
      }
      const formData = new FormData();
      const pathArr = file.name.split(".");
      const key = `${this.oss.dir}app_home_img/${generateUUID()}.${
        pathArr[pathArr.length - 1]
      }`;
      formData.append("key", key);
      formData.append("policy", this.oss.policy);
      formData.append("signature", this.oss.signature);
      formData.append("success_action_status", "200");
      formData.append("OSSAccessKeyId", this.oss.accessid);
      formData.append("file", file);

      axios({
        method: "post",
        url: this.oss.host,
        data: formData
      })
        .then(response => {
          const { err } = response;
          if (!err) {
            const imagePath = this.oss.host + "/" + key;
            this.item.imgUrl = imagePath;
            this.$emit("uploadSuccess", this.item, imagePath, this.index);

            this.item.width = imgInfo.width;
            this.item.height = imgInfo.height;

            // 下一次上传尺寸
            this.nextImgWidth = imgInfo.width;
            this.nextImgHeight = imgInfo.height;
            this.$message.success(`上传成功`);
            e.target.value = ''
          } else {
            this.$message.error(`上传失败，请稍后重试`);
            e.target.value = ''
          }
        })
        .catch(error => {
          this.$message.error(`${error}`);
          this.isUplodSuccess = false;
          e.target.value = ''
        });
    },
    // 开始上传前的准备
    async upload(e) {
      const file = e.target.files[0];
      if (file) {
        if (
          ["image/gif", "image/png", "image/jpg", "image/jpeg"].indexOf(
            file.type
          ) > -1
        ) {
          // 获取图片宽高
          const reader = new FileReader();
          reader.onloadend = () => {
            const img = new Image();
            img.onload = () => {
              let invalide = true;


              // if (this.item.hasOwnProperty("limit")) {
              if (this.nextImgWidth) {
                if (img.width !== this.nextImgWidth) {
                  invalide = false;
                  this.$alert(
                    "请上传尺寸一致的图片，图片宽度必须与首张图片相同，为 " +
                      this.nextImgWidth +
                      "px",
                    "提示"
                  );
                }
                if (img.height !== this.nextImgHeight) {
                  invalide = false;
                  this.$alert(
                    "请上传尺寸一致的图片，图片高度必须与首张图片相同，为 " +
                      this.nextImgHeight +
                      "px",
                    "提示"
                  );
                }
                // if (file.size / 1024 > this.item.limit.s) {
                //   invalide = false;
                //   this.$alert(
                //     "图片大小不能超过 " + this.item.limit.s + "k",
                //     "提示"
                //   );
                // }
              } else {
                if (img.width > 750) {
                  invalide = false;
                  this.$alert("图片宽度不能超过750px", "提示");
                }
                if(this.isSwiper && img.height !== 260) {
                  invalide = false;
                  this.$alert("轮播图的图片高度应为260px", "提示");
                }
                if (file.type === "image/gif") {
                  if (file.size / 1024 > 2048) {
                    invalide = false;
                    this.$alert("gif图片不能超过2048k", "提示");
                  }
                } else {
                  if (file.size / 1024 > 512) {
                    invalide = false;
                    this.$alert("jpeg、png图片不能超过512k", "提示");
                  }
                }
              }

              if (invalide) {
                const imgInfo = {
                  width: img.width,
                  height: img.height
                };
                this.handleFileUpload(file, imgInfo, e);
              } else {
                e.target.value = ''
              }
            };
            img.src = reader.result;
            this.$emit("beforeUpload", file, this.item, img, this.index);
          };
          reader.onerror = err => {
            console.log("reader error", err);
          };
          // 读出文件路径
          reader.readAsDataURL(file);
        } else {
          this.$alert("图片格式须为jpg、jpeg、png、gif之一！", "提示");
        }
      }
    },
    // 添加网络地址保存
    setImgUrl() {
      try {
        const img = new Image();
        img.onload = () => {
          let invalide = true;
          if (this.item.hasOwnProperty("limit")) {
            if (img.naturalWidth !== this.item.limit.w) {
              invalide = false;
              this.$alert("图片宽度必须为 " + this.item.limit.w + "px", "提示");
            }
            if (img.naturalHeight !== this.item.limit.h) {
              invalide = false;
              this.$alert("图片高度必须为 " + this.item.limit.h + "px", "提示");
            }
          } else {
            if (img.naturalWidth > 750) {
              invalide = false;
              this.$alert("图片宽度不能超过750px", "提示");
            }
          }
          if (invalide) {
            const width = 750;
            const height =
              img.naturalHeight * (750 / img.naturalWidth).toFixed(4);
            this.item.width = width;
            this.item.height = height;
            this.item.imgUrl = img.src;
            this.dialogShow = false;
            this.$emit("uploadSuccess", this.item, img, this.index);
          }
        };
        img.src = this.imgUrl;
      } catch (e) {
        console.warn(e);
      }
    },
    delImg(item) {
      item.imgUrl = "";
      item.width = null;
      item.height = null;
     },
    clickTab(tab) {
      this.currentTab = tab.name;
    },
    validateUpload () {
      return this.item.imgUrl != ''
    }
  }
};
</script>

<style lang="scss">
#netImgDialog {
  .el-dialog__body {
    padding-top: 10px;
  }
}

.upload-container {
  display: flex;

  .upload-tips {
    display: block;
    font-size: 12px;
    line-height: 1.5;
    padding: 5px;
    color: #666;

    h3 {
      margin: 0;
    }
  }
}

.el-upload-dragger {
  width: 100% !important;
  height: 100% !important;
  min-width: 150px;

  .el-icon-upload {
    margin-top: 20px;
  }
}

.image-preview {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ecf5ff;

  .fa-window-close {
    position: absolute;
    top: 0;
    right: 0;
    color: #666;
    font-size: 22px;
    z-index: 99;
  }
}

.upload-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 150px;
  height: 115px;
  cursor: pointer;
}
.mt-10 {
  margin-top: 10px;
}
</style>
