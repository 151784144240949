/* const typeMap = {
  'text': '纯文本输入框',
  'input-number': '纯数字输入框',
  'font': '字体样式',
  'upload': '图片上传控件',
  'select': '下拉选择',
  'radio': 'Radio单选',
  'datetime': '日期时间选择器',
  'color-picker': '颜色拾取器',
  'desc': '描述文本内容'
} */

module.exports = {
  'douyinHotSale': {
    "type": "app-blcok-click",
    "active": false,
    "title": "抖音热销榜",
    "domId": "",
    "domName": "",
    "action": {
      "type": "hotSale",
      "title": "配置",
      "config": {
        "isShow": true
      }
    },
  },
  'hotSale': {
    "type": "app-blcok-click",
    "active": false,
    "title": "精品推荐",
    "domId": "",
    "domName": "",
    "action": {
      "type": "hotSale",
      "title": "配置",
      "config": {
        "isShow": true
      }
    },
  },
  'highDiscount': {
    "type": "app-blcok-click",
    "active": false,
    "title": "高折扣榜",
    "domId": "",
    "domName": "",
    "action": {
      "type": "hotSale",
      "title": "配置",
      "config": {
        "isShow": true
      }
    },
  },
  'highCommission': {
    "type": "app-blcok-click",
    "active": false,
    "title": "高佣榜",
    "domId": "",
    "domName": "",
    "action": {
      "type": "hotSale",
      "title": "配置",
      "config": {
        "isShow": true
      }
    },
  },
  'recommendGoods': {
    "type": "app-blcok-click",
    "active": false,
    "title": "高佣榜",
    "domId": "",
    "domName": "",
    "action": {
      "type": "hotSale",
      "title": "配置",
      "config": {
        "isShow": true
      }
    },
  },
  'app-swiper': {
    title: '轮播图',
    domId: '',
    domName: '',
    // base: [{
    //   type: 'radio',
    //   label: '自动播放',
    //   attr: 'autoplay',
    //   val: true,
    //   options: [{
    //     name: '是',
    //     val: true
    //   }, {
    //     name: '否',
    //     val: false
    //   }],
    //   isNecessary: false
    // }, {
    //   type: 'radio',
    //   label: '显示分页',
    //   attr: 'pagination',
    //   val: true,
    //   options: [{
    //     name: '是',
    //     val: true
    //   }, {
    //     name: '否',
    //     val: false
    //   }],
    //   isNecessary: false
    // }],
    // style: [{
    //   type: 'color-picker',
    //   label: '背景颜色',
    //   attr: 'background-color',
    //   val: 'rgba(255, 255, 255, 0)',
    //   isNecessary: true
    // }, {
    //   type: 'input-number',
    //   label: '上边距',
    //   attr: 'padding-top',
    //   val: 0,
    //   unit: 'px',
    //   min: 0,
    //   max: 50,
    //   step: 1,
    //   isNecessary: true
    // }, {
    //   type: 'input-number',
    //   label: '右边距',
    //   attr: 'padding-right',
    //   val: 0,
    //   unit: 'px',
    //   min: 0,
    //   max: 50,
    //   step: 1,
    //   isNecessary: true
    // }, {
    //   type: 'input-number',
    //   label: '下边距',
    //   attr: 'padding-bottom',
    //   val: 0,
    //   unit: 'px',
    //   min: 0,
    //   max: 50,
    //   step: 1,
    //   isNecessary: true
    // }, {
    //   type: 'input-number',
    //   label: '左边距',
    //   attr: 'padding-left',
    //   val: 0,
    //   unit: 'px',
    //   min: 0,
    //   max: 50,
    //   step: 1,
    //   isNecessary: true
    // }],
    action: {
      type: 'app-swiper-click',
      title: '图片项配置',
      width: null,
      height: null,
      config: [{
        width: null,
        height: null,
        imgUrl: '',
        type: 'none',
        value: ''
      }]
    }
  },
  'split-image': {
    title: '分割图',
    domId: '',
    domName: '',
    base: [{
      type: 'radio',
      label: '等分数量',
      attr: 'split',
      val: 1,
      options: [{
          name: '单图',
          val: 1
        }, {
          name: '二等分图',
          val: 2
        },
        {
          name: '三等分图',
          val: 3
        }
      ],
      isNecessary: true
    }],
    action: {
      type: 'split-image-click',
      title: '图片项配置',
      width: null,
      height: null,
      config: [{
        width: null,
        height: null,
        imgUrl: '',
        type: 'none',
        value: ''
      }]
    }
  },
  'king-kong': {
    title: '金刚位',
    domId: '',
    domName: '',
    active: false,
    base: [{
      type: 'radio',
      label: '单排个数',
      attr: 'split',
      val: 4,
      options: [{
          name: '4个',
          val: 4
        },
        {
          name: '5个',
          val: 5
        }
      ],
      isNecessary: true
    }],
    style: [{
      type: 'color-picker',
      label: '字体颜色',
      attr: 'color',
      val: '#333333',
      isNecessary: true
    }],
    action: {
      type: 'king-kong-click',
      title: '内容配置',
      width: null,
      height: null,
      config: [{
        width: null,
        height: null,
        imgUrl: '',
        label: '',
        type: 'none',
        value: ''
      }]
    }
  },
  'v-goods-bang': {
    title: '外露榜单',
    domId: '',
    domName: '',
    active: false,
    style: [],
    base: [{
      type: 'text',
      label: '标题文案',
      val: '',
      maxLength: 16,
      placeholder: '请输入标题',
      isNecessary: true,
      isShow: false
    },
    {
      type: 'text',
      label: '描述文案',
      val: '',
      maxLength: 22,
      placeholder: '请输入描述文案',
      isNecessary: true,
      isShow: false
    },{
      isJumpMod: true,
      label: '跳转地址',
      type: 'module',
      value: 'sample',
      jumpTypes: ['module','showtime','link'],
      isNecessary: true
    }],
    action: {
      type: 'v-goods-bang-click',
      title: '内容配置',
      config: [{
        goodsId: null,
        tempGoodsData: {
          item_id__white_image: '',
        }
      },
      {
        goodsId: null,
        tempGoodsData: {
          item_id__white_image: '',
        }
      },
      {
        goodsId: null,
        tempGoodsData: {
          item_id__white_image: '',
        }
      },
      {
        goodsId: null,
        tempGoodsData: {
          item_id__white_image: '',
        }
      }]
    }
  }
}