<template>
  <div class="el-form-item small el-form-item--mini">
    <div class="el-form-item__content">
      <div class="upload-container">
        <div class="el-upload el-upload--text">
          <div class="el-upload-dragger">
            <i class="el-icon-upload"></i>
            <div
              v-if="item.imgUrl"
              class="image-preview"
              :style="{ backgroundImage: 'url(' + item.imgUrl + ')' }"
            >
              <i class="fa fa-window-close" @click.stop="delImg(item)"></i>
            </div>
            <div v-else class="el-upload__text">
              上传图片
              <em></em>
            </div>
          </div>
          <input @change="upload" type="file" name="photoImg" class="J-imgUploadFile upload-input" />
        </div>
        <div class="upload-tips"><a-alert :message="tip" type="info" /></div>
      </div>
      <div class="mt-10">
        <a-alert v-if="!item.imgUrl" type="error" message="请上传图片"></a-alert>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { baseURL } from "@/config/index";
import { generateUUID } from "@/utils/index";
import { getOssToken } from "@/service/user";
export default {
  name: "SimpleImageUploader",
  data() {
    return {
      uploadAction: `${baseURL}/api/v1/upload-file/`,
      oss: null,
      nextImgWidth:
        this.allItems && this.allItems.length && this.allItems[0]["width"],
      nextImgHeight:
        this.allItems && this.allItems.length && this.allItems[0]["height"]
    };
  },
  props: {
    item: {
      type: Object,
      default: function() {
        return {
          imgUrl: ""
        };
      }
    },
    tip: {
      type: String,
      default: "建议尺寸 718*110"
    },
    dir: {
      type: String,
      default: "app_home_img"
    }
  },
  watch: {
    item: {
      handler(newValue) {
        this.$set(this.item, "imgUrl", newValue.imgUrl);
        // this.item.imgUrl = newValue.imgUrl
      },
      deep: true
    }
  },
  methods: {
    // 获取osstoken
    async getOssToken() {
      const { err, res } = await getOssToken();
      if (!err && res.success) this.oss = res.data;
    },
    // 文件上传方法
    async handleFileUpload(file, imgInfo, e) {
      if (!this.oss) await this.getOssToken();
      if (!this.oss) {
        this.$message.warn("上传失败");
        return false;
      }
      const formData = new FormData();
      const pathArr = file.name.split(".");
      const key = `${this.oss.dir}${this.dir}/${generateUUID()}.${
        pathArr[pathArr.length - 1]
      }`;
      formData.append("key", key);
      formData.append("policy", this.oss.policy);
      formData.append("signature", this.oss.signature);
      formData.append("success_action_status", "200");
      formData.append("OSSAccessKeyId", this.oss.accessid);
      formData.append("file", file);

      axios({
        method: "post",
        url: this.oss.host,
        data: formData
      })
        .then(response => {
          const { err } = response;
          if (!err) {
            const imagePath = this.oss.host + "/" + key;
            this.item.imgUrl = imagePath;
            this.$emit("uploadSuccess", this.item, imagePath, this.index);
            this.$message.success(`上传成功`);
            e.target.value = "";
          } else {
            this.$message.error(`上传失败，请稍后重试`);
            e.target.value = "";
          }
        })
        .catch(error => {
          this.$message.error(`${error}`);
          this.isUplodSuccess = false;
          e.target.value = "";
        });
    },
    // 开始上传前的准备
    async upload(e) {
      const file = e.target.files[0];
      if (file) {
        if (
          ["image/gif", "image/png", "image/jpg", "image/jpeg"].indexOf(
            file.type
          ) > -1
        ) {
          // 获取图片宽高
          const reader = new FileReader();
          reader.onloadend = () => {
            const img = new Image();
            img.onload = () => {
              let invalide = true;

              if (img.width > 750) {
                invalide = false;
                this.$alert("图片宽度不能超过750px", "提示");
              }
              if (this.isSwiper && img.height !== 260) {
                invalide = false;
                this.$alert("轮播图的图片高度应为260px", "提示");
              }
              if (file.type === "image/gif") {
                if (file.size / 1024 > 2048) {
                  invalide = false;
                  this.$alert("gif图片不能超过2048k", "提示");
                }
              } else {
                if (file.size / 1024 > 512) {
                  invalide = false;
                  this.$alert("jpeg、png图片不能超过512k", "提示");
                }
              }

              if (invalide) {
                const imgInfo = {
                  width: img.width,
                  height: img.height
                };
                this.handleFileUpload(file, imgInfo, e);
              } else {
                e.target.value = "";
              }
            };
            img.src = reader.result;
            this.$emit("beforeUpload", file, this.item, img, this.index);
          };
          reader.onerror = err => {
            console.log("reader error", err);
          };
          // 读出文件路径
          reader.readAsDataURL(file);
        } else {
          this.$alert("图片格式须为jpg、jpeg、png、gif之一！", "提示");
        }
      }
    },
    // 添加网络地址保存
    setImgUrl() {
      try {
        const img = new Image();
        img.onload = () => {
          let invalide = true;
          if (this.item.hasOwnProperty("limit")) {
            if (img.naturalWidth !== this.item.limit.w) {
              invalide = false;
              this.$alert("图片宽度必须为 " + this.item.limit.w + "px", "提示");
            }
            if (img.naturalHeight !== this.item.limit.h) {
              invalide = false;
              this.$alert("图片高度必须为 " + this.item.limit.h + "px", "提示");
            }
          } else {
            if (img.naturalWidth > 750) {
              invalide = false;
              this.$alert("图片宽度不能超过750px", "提示");
            }
          }
          if (invalide) {
            const width = 750;
            const height =
              img.naturalHeight * (750 / img.naturalWidth).toFixed(4);
            this.item.width = width;
            this.item.height = height;
            this.item.imgUrl = img.src;
            this.dialogShow = false;
            this.$emit("uploadSuccess", this.item, img, this.index);
          }
        };
        img.src = this.imgUrl;
      } catch (e) {
        console.warn(e);
      }
    },
    delImg(item) {
      item.imgUrl = "";
      item.width = null;
      item.height = null;
    },
    validateUpload() {
      return this.item.imgUrl != "";
    }
  }
};
</script>

<style lang="scss">
#netImgDialog {
  .el-dialog__body {
    padding-top: 10px;
  }
}

.upload-container {
  display: flex;

  .upload-tips {
    display: block;
    font-size: 12px;
    line-height: 1.5;
    padding: 5px;
    color: #666;

    h3 {
      margin: 0;
    }
  }
}

.el-upload-dragger {
  width: 100% !important;
  height: 100% !important;
  min-width: 150px;

  .el-icon-upload {
    margin-top: 20px;
  }
}

.image-preview {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ecf5ff;

  .fa-window-close {
    position: absolute;
    top: 0;
    right: 0;
    color: #666;
    font-size: 22px;
    z-index: 99;
  }
}

.upload-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 150px;
  height: 115px;
  cursor: pointer;
}
.mt-10 {
  margin-top: 10px;
}
</style>
