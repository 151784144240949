<!--
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-05-05 10:06:21
 * @LastEditTime: 2020-07-16 09:25:11
 -->
<template>
  <div>
    <template v-if="slides && slides.length">
      <div class="form-list-panel" v-for="(slide, idx) in slides" :key="idx">
        <template>
          <el-form-item class="small" label="商品ID：">
            <a-input
              v-model="slide.goodsId"
              placeholder="请输入商品ID"
              style="width: 200px"
              @blur="checkGoods(idx)"
            ></a-input>
            <div class="pt-10">
              <a-alert v-if="!slide.goodsId" type="error" message="请填写商品ID"></a-alert>
              <a-alert
                v-if="slide.goodsId && slide.errorMsg"
                type="error"
                :message="slide.errorMsg"
              ></a-alert>
            </div>
          </el-form-item>
        </template>
        <div class="list-item-opt">
          <a href="javascript:;" v-if="idx !== 0" @click="upBanner(idx)">
            <i class="el-icon-arrow-up"></i>
          </a>
          <a href="javascript:;" v-if="idx !== slides.length - 1" @click="downBanner(idx)">
            <i class="el-icon-arrow-down"></i>
          </a>
          <a href="javascript:;" v-if="slides.length > 4" @click="delBanner(idx)">
            <i class="el-icon-delete"></i>
          </a>
        </div>
      </div>
    </template>
    <el-button
      icon="el-icon-plus"
      style="margin-top: 15px"
      v-if="slides.length < 10"
      round
      @click="addBanner"
      >添加商品</el-button
    >
  </div>
</template>

<script>
import util from '@/utils/h5-marker-util.js';
import { setEditStatus } from '@/utils/index.js';
import compConfig from '@/config/app.comp.config.js';
import { getGoodsBrief } from '@/service/goods';

export default {
  data() {
    return {
      defaultConf: util.copyObj(compConfig['v-goods-bang']),
      slides: this.banners,
    };
  },
  components: {},
  props: {
    banners: {
      type: Array,
    },
  },
  watch: {
    banners: {
      handler(val) {
        this.slides = val;
        setEditStatus('true');
      },
      deep: true,
    },
  },
  methods: {
    showClick(banner, idx) {
      // 轮播图只可配置外链
      this.$bus.$emit('click:show', idx, ['outside', 'code']);
    },
    upBanner(idx) {
      const tmp = util.copyObj(this.slides[idx]);
      this.slides.splice(idx, 1);
      this.slides.splice(idx - 1, 0, tmp);
    },
    downBanner(idx) {
      const tmp = util.copyObj(this.slides[idx]);
      this.slides.splice(idx, 1);
      this.slides.splice(idx + 1, 0, tmp);
    },
    delBanner(idx) {
      this.slides.splice(idx, 1);
    },
    addBanner() {
      if (this.slides.length < 10) {
        this.slides.push(util.copyObj(this.defaultConf.action.config[0]));
      } else {
        this.$alert('最多添加10个图片项！');
      }
    },
    uploadSuccess(item, img, idx) {
      console.log('uploadSuccess', item, img, idx);
    },
    // 判断商品id是否正确
    async checkGoods(idx) {
      if (this.slides[idx].goodsId) {
        const { err, res } = await getGoodsBrief({
          item_ids: this.slides[idx].goodsId,
        });
        if (!err) {
          if (res.success) {
            if (res.data && !res.data[this.slides[idx].goodsId]) {
              this.$message.error('折一商品库未找到该ID的商品');
              this.slides[idx].errorMsg = '折一商品库未找到该ID的商品';
            } else {
              const item = {
                errorMsg: null,
                liveType: res.data[this.slides[idx].goodsId].live_type,
                goodsId: this.slides[idx].goodsId,
                tempGoodsData: res.data[this.slides[idx].goodsId],
              };
              // this.slides[idx].errorMsg = null;
              // // 写入 客户端需要的参数
              // this.item.liveType = res.data[this.item.value].live_type;
              // this.item.goodsId = res.data[this.item.value].item_id__id;
              this.slides.splice(idx, 1, item);
            }
          } else {
            const item = JSON.parse(JSON.stringify(this.slides[idx]));
            item.errorMsg = '折一商品库未找到该ID的商品';
            this.slides.splice(idx, 1, item);
          }
        }
      }
    },
    // 校验
    async validate() {
      const isOk = await this.slides.every(item => item.goodsId && item.errorMsg === null);
      return isOk;
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss"></style>
