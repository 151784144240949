<!--
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-05-05 10:06:21
 * @LastEditTime: 2020-05-14 19:31:00
 -->
<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="是否展示：">
        <el-radio-group v-model="config.isShow">
          <el-radio :label="true">展示</el-radio>
          <el-radio :label="false">隐藏</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import util from "@/utils/h5-marker-util.js";
import { setEditStatus } from "@/utils/index.js";
import compConfig from "@/config/comp.config.js";

export default {
  props: {
    blockType: {
      default: ""
    },
    config: {
      default: function() {
        return {
          isShow: true
        };
      }
    }
  },
  data() {
    return {
      defaultConf: util.copyObj(compConfig[this.blockType]),
      compConfig
    };
  },
  watch: {
    config: {
      handler() {
        setEditStatus("true");
      },
      deep: true
    }
  },
  components: {},

  methods: {}
};
</script>

<style rel="stylesheet/scss" lang="scss">
</style>
