/*
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-05-08 11:07:57
 * @LastEditTime: 2020-07-06 18:34:01
 */
module.exports = [
  {
    title: '首页组件',
    collapse: false,
    items: [
      {
        key: 'app-swiper',
        text: '轮播图',
        icon: 'fa-caret-square-o-right'
      },
      {
        key: 'split-image',
        text: '图片',
        icon: 'fa-file-image-o'
      },
      {
        key: 'king-kong',
        text: '金刚位',
        icon: 'fa-file-image-o'
      },
      {
        key: 'v-goods-bang',
        text: '外露榜单',
        icon: 'fa-file-image-o'
      }
    ]
  }
]
