<!--
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 白青
 * @Date: 2020-05-05 10:06:21
 * @LastEditTime: 2020-07-18 15:17:12
 -->
<template>
  <div>
    <template v-if="slides && slides.length">
      <div class="form-list-panel" v-for="(slide, idx) in slides" :key="idx">
        <a-form-model layout="horizontal" :model="slide" ref="kingkongForm" :rules="rules">
          <upload
            :label="'图片' + (idx + 1)"
            :index="idx"
            :item="slide"
            :allItems="slides"
            v-on:uploadSuccess="uploadSuccess"
          ></upload>
          <a-form-model-item
            prop="label"
            class="small"
            label="导航文案："
            :label-col="{span: 5}"
            :wrapperCol="{span: 19}"
          >
            <a-input v-model="slide.label" :maxLength="5" />
          </a-form-model-item>

          <a-form-model-item
            class="small"
            label="跳转地址："
            :label-col="{span: 5}"
            :wrapperCol="{span: 19}"
          >
            <jump :item="slide" />
          </a-form-model-item>
          <div class="list-item-opt">
            <a href="javascript:;" v-if="idx !== 0" @click="upBanner(idx)">
              <i class="el-icon-arrow-up"></i>
            </a>
            <a href="javascript:;" v-if="idx !== slides.length - 1" @click="downBanner(idx)">
              <i class="el-icon-arrow-down"></i>
            </a>
            <a href="javascript:;" v-if="slides.length > 1" @click="delBanner(idx)">
              <i class="el-icon-delete"></i>
            </a>
          </div>
        </a-form-model>
      </div>
    </template>
    <el-button icon="el-icon-plus" style="margin-top:15px;" round @click="addBanner">添加金刚位</el-button>
  </div>
</template>

<script>
import util from "@/utils/h5-marker-util.js";
import { setEditStatus } from "@/utils/index.js";
import compConfig from "@/config/app.comp.config.js";
import upload from "@/components/h5maker/common/upload.vue";
import jump from "@/components/h5maker/common/jump.vue";

export default {
  data() {
    return {
      defaultConf: util.copyObj(compConfig["king-kong"]),
      slides: this.banners,
      rules: {
        label: [{ required: true, message: "请选择跳转类型", trigger: "blur" }]
      }
    };
  },
  components: {
    upload,
    jump
  },
  props: {
    banners: {
      type: Array
    }
  },
  watch: {
    banners: {
      handler(val) {
        this.slides = val;
        setEditStatus("true");
      },
      deep: true
    }
  },
  methods: {
    showClick(banner, idx) {
      // 轮播图只可配置外链
      this.$bus.$emit("click:show", idx, ["outside", "code"]);
    },
    upBanner(idx) {
      const tmp = util.copyObj(this.slides[idx]);
      this.slides.splice(idx, 1);
      this.slides.splice(idx - 1, 0, tmp);
    },
    downBanner(idx) {
      const tmp = util.copyObj(this.slides[idx]);
      this.slides.splice(idx, 1);
      this.slides.splice(idx + 1, 0, tmp);
    },
    delBanner(idx) {
      this.slides.splice(idx, 1);
    },
    addBanner() {
      if (this.slides.length < 10) {
        this.slides.push(util.copyObj(this.defaultConf.action.config[0]));
      } else {
        this.$alert("最多添加10个图片项！");
      }
    },
    uploadSuccess(item, img, idx) {
      console.log("uploadSuccess", item, img, idx);
    },
    async validate() {
      const isOkImg = this.slides.every(item => item.label && item.imgUrl);
      let isOk = [];
      for (let i = 0; i < this.$refs.kingkongForm.length; i++) {
        try {
          let res = await this.$refs.kingkongForm[i].validate();
          isOk.push(res);
        } catch (error) {
          console.log('appKingkongItem validate', error);
          isOk.push(false);
        }
      }
      const isTextOk = isOk.every(item => item)
      return isTextOk && isOkImg;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
</style>
