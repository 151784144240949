/*
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-05-18 11:36:57
 * @LastEditTime: 2020-07-07 15:39:19
 */
module.exports = [
  {
    title: '折一组件',
    collapse: false,
    items: [
      {
        key: 'kuran-swiper',
        text: '轮播图',
        icon: 'fa-caret-square-o-right',
      },
      {
        key: 'split-image',
        text: '图片',
        icon: 'fa-image',
      },
      {
        key: 'kuran-goods-fullwidth',
        text: '商品',
        icon: 'fa-shopping-cart',
      },
      {
        key: 'kuran-title',
        text: '标题栏',
        icon: 'fa-header',
      },
      {
        key: 'kuran-floor-menu',
        text: '导航栏',
        icon: 'fa-list-ol',
      },
      {
        key: 'kuran-paragraph',
        text: '文本',
        icon: 'fa-paragraph',
      },
      {
        key: 'kuran-form',
        text: '报名表单',
        icon: 'fa-list',
      },
      {
        key: 'king-kong',
        text: '金刚位',
        icon: 'fa-cube',
      },
      {
        key: 'v-goods-bang',
        text: '外露榜单',
        icon: 'fa-puzzle-piece',
      },
    ],
  },
];
