<!--
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-05-05 10:06:21
 * @LastEditTime: 2020-05-26 17:28:30
 -->
<template>
  <header class="app-header">
    <div>
      <a href="javascript:;" class="back-btn-text" @click="goBack">
        <a-icon type="left" />返回
      </a>
      {{title}}
    </div>
  </header>
</template>

<script>
// import { setEditStatus } from "@/utils/index.js";
export default {
  name: "AppHeader",
  props: {
    type: {
      default: "h5"
    }
  },
  data() {
    return {
      title: this.type === "h5" ? "专题制作" : "首页配置"
    };
  },
  methods: {
    goBack() {
      const hasDataChanged = window.localStorage.getItem("dataChanged");
      if (hasDataChanged === "true") {
        const backConfirm = window.confirm(
          "系统可能不会保存您所做的更改，确认要返回吗？"
        );
        if (backConfirm) {
          this.$router.go(-1);
        }
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-header {
  position: relative;
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  background: rgba(38, 38, 38, 1);
  font-size: 18px;
  color: #fff;

  .header-logo {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin-right: 15px;
  }
}
.back-btn-text {
  margin-right: 30px;
}
</style>
