<template>
  <div>
    <el-form
      :model="item"
      :rules="rules"
      label-width="0"
      ref="dynamicValidateForm"
      class="demo-dynamic"
    >
      <el-form-item prop="type">
        <el-radio-group v-model="item.type" @change="resetValue" class="jump-types">
          <el-radio v-if="types.includes('none')" label="none">无</el-radio>
          <el-radio v-if="types.includes('goods')" label="goods">商品</el-radio>
          <el-radio v-if="types.includes('module')" label="module">模块</el-radio>
          <el-radio v-if="types.includes('showtime')" label="showtime">专场</el-radio>
          <el-radio v-if="types.includes('link')" label="link">链接</el-radio>
        </el-radio-group>
      </el-form-item>
      <div>
        <a-input
          v-model="item.value"
          v-if="item.type === 'goods'"
          placeholder="请输入商品ID"
          style="width: 200px"
          @blur="checkGoods"
        ></a-input>
        <el-input
          v-model="item.value"
          v-if="item.type === 'link'"
          placeholder="请输入跳转链接"
          @change="handlerCheckUrlFormat"
        ></el-input>
        <el-input
          v-model="item.value"
          v-if="item.type === 'showtime'"
          placeholder="请输入专场ID"
          @blur="handlerCheckShowtimeId"
        ></el-input>
        <el-select v-model="item.value" v-if="item.type === 'module'" placeholder="请选择">
          <el-option
            v-for="mo in moduleOptions"
            :key="mo.value"
            :label="mo.label"
            :value="mo.value"
          ></el-option>
        </el-select>
        <!--商品id判断-->
        <div class="pt-10" v-if="item.type === 'goods'">
          <a-alert v-if="!item.value" type="error" message="请填写商品ID"></a-alert>
          <a-alert
            v-if="item.value && item.errorMsg"
            type="error"
            :message="item.errorMsg"
          ></a-alert>
        </div>
        <!--专场id判断-->
        <div class="pt-10" v-if="item.type === 'showtime'">
          <a-alert v-if="!item.value" type="error" message="请输入专场ID"></a-alert>
          <a-alert
            v-if="item.value && item.errorMsg"
            type="error"
            :message="item.errorMsg"
          ></a-alert>
        </div>
        <!--链接判断-->
        <div class="pt-10" v-if="item.type === 'link'">
          <a-alert
            v-if="!item.value"
            type="error"
            message="请填写链接地址, 例如：https://data.goumee.com/index.html，必须包含goumee.com"
          ></a-alert>
          <a-alert
            v-if="item.value && item.errorMsg"
            type="error"
            :message="item.errorMsg"
          ></a-alert>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getGoodsBrief } from '@/service/goods';
import { checkSpecialExist } from '@/service/operate';
import { checkUrl } from '@/utils/validator';
export default {
  props: {
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
    types: {
      default: function () {
        return ['none', 'goods', 'module', 'showtime', 'link'];
      },
      type: Array,
    },
    // imgUrl: {
    //   type: String,
    //   default: ''
    // },
    // type: {
    //   type: String,
    //   default: 'none'
    // },
    // value: {
    //   type: String,
    //   default: ''
    // },
    // width: {
    //   type: Number,
    //   default: 690
    // },
    // height: {
    //   type: Number,
    //   default: 240
    // }
  },
  data() {
    return {
      checkUrl,
      // 模块列表
      moduleOptions: [
        { label: '免费寄样', value: 'sample' },
        // { label: "百人拼播", value: "pinbo" },
        { label: '新人专区', value: 'freshman' },
        { label: '每日上新', value: 'dailyUp' },
        { label: '品牌专场', value: 'brand' },
        { label: '高佣榜', value: 'highCommission' },
        { label: '高折扣榜', value: 'highDiscount' },
        { label: '爆品库', value: 'bao' },
        { label: '抖音小店', value: 'douyin' },
        { label: '折一优选', value: 'kuranSelected' },
      ],
      rules: {
        type: [{ required: true, message: '请选择跳转类型', trigger: 'blur' }],
      },
    };
  },
  methods: {
    resetValue() {
      this.item.errorMsg = null;
      if (this.item.type === 'module') {
        this.item.value = 'sample';
      } else {
        this.item.value = null;
      }
    },
    // 检查链接地址是否正确
    checkUrlFormat(val) {
      if (val && val.length) {
        return /(http|https):\/\/[\w.-]+\.goumee\.com\/([\w.]+?)\S*/.test(val);
      } else {
        return false;
      }
    },
    // 触发链接地址检查
    handlerCheckUrlFormat() {
      const c = !this.checkUrlFormat(this.item.value);
      if (c) {
        this.item.errorMsg = '链接地址格式错误，必须包含goumee.com';
      } else {
        this.item.errorMsg = null;
      }
      this.$forceUpdate();
    },
    // 触发链接地址检查专场id
    async handlerCheckShowtimeId() {
      const { err, res } = await checkSpecialExist(this.item.value);
      if (!err) {
        if (res.success) {
          this.item.errorMsg = null;
        } else {
          this.item.errorMsg = '专场Id不存在';
          this.$forceUpdate();
        }
        this.$forceUpdate();
      }
    },
    // 判断商品id是否正确
    async checkGoods() {
      if (this.item.value) {
        const { err, res } = await getGoodsBrief({
          item_ids: this.item.value,
        });
        if (!err) {
          if (res.success) {
            if (res.data && !res.data[this.item.value]) {
              this.$message.error('折一商品库未找到该ID的商品');
              this.item.errorMsg = '折一商品库未找到该ID的商品';
              this.$forceUpdate();
            } else {
              this.item.errorMsg = null;
              this.$forceUpdate();
              // 写入 客户端需要的参数
              this.item.liveType = res.data[this.item.value].live_type;
              this.item.goodsId = res.data[this.item.value].item_id__id;
            }
          } else {
            this.item.errorMsg = '折一商品库未找到该ID的商品';
            this.$forceUpdate();
          }
        }
      }
    },
    // 验证表单
    validateJump() {
      let res = false;
      switch (this.item.type) {
        case 'none':
          res = true;
          break;
        case 'link':
          debugger;
          res = this.item.value && this.checkUrlFormat(this.item.value);
          break;
        default:
          res = this.item.value;
          break;
      }
      return res;
    },
  },
  // watch: {
  //   option() {
  //     this.config = this.option;
  //   },
  //   tabs() {
  //     this.showTabs = this.tabs;
  //   },
  //   show() {
  //     this.dialogShow = this.show;
  //     if (this.dialogShow) {
  //       this.currentTab = this.getCurrentTab();
  //       this.outsideVal = this.getVal("outside");
  //       this.pageVal = this.getVal("page");
  //       this.telVal = this.getVal("tel");
  //       this.returnVal = null;
  //     }
  //   }
  // },
  // methods: {
  //   getVal(type) {
  //     if (
  //       this.config &&
  //       this.config.action &&
  //       this.config.action.config.length &&
  //       this.config.action.config[this.index].click
  //     ) {
  //       if (this.config.action.config[this.index].click.type === type) {
  //         return this.config.action.config[this.index].click.href;
  //       }
  //     }
  //     return "";
  //   },
  //   getOldVal() {
  //     return this.config &&
  //       this.config.action.config &&
  //       this.config.action.config.length
  //       ? this.config.action.config[this.index].click
  //       : null;
  //   },
  //   getCurrentTab() {
  //     if (this.showTabs && this.showTabs.length === 1) {
  //       return this.showTabs[0];
  //     }
  //     return this.config &&
  //       this.config.action.config &&
  //       this.config.action.config.length &&
  //       this.config.action.config[this.index].click
  //       ? this.config.action.config[this.index].click.type
  //       : "outside";
  //   },
  //   setPageAction(id) {
  //     this.pageVal = id;
  //     this.returnVal = {
  //       type: "page",
  //       href: id
  //     };
  //   },
  //   clickTab(tab) {
  //     this.currentTab = tab.name;
  //   },
  //   sure() {
  //     this.dialogShow = false;
  //     if (this.currentTab === "outside" && this.outsideVal) {
  //       this.returnVal = {
  //         type: "outside",
  //         href: this.outsideVal
  //       };
  //     }
  //     if (this.currentTab === "tel" && this.telVal) {
  //       this.returnVal = {
  //         type: "tel",
  //         href: this.telVal
  //       };
  //     }
  //     this.$bus.$emit("click:submit", this.index, this.returnVal);
  //   }
  // }
};
</script>

<style lang="scss">
#click {
  .el-dialog__body {
    padding-top: 10px;

    .choose-panel {
      line-height: 2.8;
    }
  }
}
.pt-10 {
  padding-top: 10px;
}
.jump-types {
  .el-radio {
    margin-right: 10px;
  }
}
</style>
